<template>
  <app-modal-add-customer
    :show="showModalCustomer"
    @close="showModalCustomer = false"
    @refresh="refresh"
  />

  <!-- Modal Active Business-->
  <app-modal
    v-if="hasActiveBusiness"
    :show="showModalActive"
    @close="showModalActive = false"
    :textButtonCancel="$t('payment.info.active_later')"
  >
    <template v-slot:title>{{
      $t("payment.info.active_your_account_title")
    }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("customer.active_your_account_description") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="$router.push({ name: 'business' })"
        @click="showModalActive = false"
        :showf70Icon="false"
        :primary="false"
        class="
          sm:ml-3 sm:w-auto
          bg-primary
          hover:bg-primary-100
          text-white
          outline-none
        "
      >
        {{ $t("payment.info.active_account") }}
      </app-button>
    </template>
  </app-modal>

  <div>
    <app-table
      :loading="apiPaginationLoading"
      :apiResponse="apiPaginationResponse?.data ?? []"
      :showNumber="false"
      @params-changed="paramsChanged"
      @empty-button-clicked="createCustomer"
      emptyIcon="app-icon-empty-customer"
      :emptyTitle="$t('customer.empty.title')"
      :emptyButton="$t('customer.add_new_customer_button')"
      :emptyDescription="$t('customer.empty.description')"
      :filters="[
        {
          key: 'filter[name]',
          type: 'text',
          placeholder: $t('customer.customer_name'),
          value: '',
        },
        {
          key: 'filter[email]',
          type: 'text',
          placeholder: $t('customer.customer_email'),
          value: '',
        },
        {
          key: 'filter[phone_no]',
          type: 'number',
          placeholder: $t('customer.customer_phone_no'),
          value: '',
        },
        {
          key: 'filter[created_at_between]',
          type: 'date',
          placeholder: $t('wallet.payout.date'),
          value: '',
        },
      ]"
    >
      <template v-slot:title>
        <div>
          <p class="font-medium">
            {{ $t("layout.navbar.customer") }}
          </p>
        </div>
      </template>
      <template v-slot:button>
        <app-button
          width="w-1/8"
          :showf70Icon="false"
          @click="createCustomer"
          :loading="apiPaginationLoading"
        >
          <template v-slot:icon>
            <app-icon-outline name="PlusIcon" class="h-6 w-6 text-white mr-2" />
          </template>
          {{ $t("customer.add_new_customer_button") }}
        </app-button>
      </template>
      <template v-slot:header>
        <th class="font-bold">
          {{ $t("customer.table_header.customer_name") }}
        </th>
        <th class="font-bold">
          {{ $t("customer.table_header.customer_email") }}
        </th>
        <th class="font-bold">
          {{ $t("customer.table_header.customer_phone_no") }}
        </th>
        <th class="font-bold">
          {{ $t("customer.table_header.customer_date_created") }}
        </th>
        <th class="font-bold">{{ $t("customer.table_header.action") }}</th>
      </template>

      <template v-slot:body="data">
        <td>
          {{ data.model?.name }}
        </td>
        <td>
          {{ data.model?.email }}
        </td>
        <td>
          {{ data.model?.phone_no ?? "-" }}
        </td>
        <td>
          {{ $moment(data.model.created_at).format("DD MMM YYYY hh:mm a") }}
        </td>
        <td>
          <div class="flex space-x-2 text-gray-400">
            <p @click.stop="onClickCustomer(data.model)">
              {{ $t("subscription.edit") }}
            </p>
            <p @click.stop="openDeleteConfirmation(data.model)">
              {{ $t("subscription.delete") }}
            </p>
          </div>
        </td>
      </template>
    </app-table>
  </div>

  <app-modal
    :show="showDeleteConfirmation"
    @close="showDeleteConfirmation = false"
  >
    <template v-slot:title>{{ $t("customer.delete_customer") }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("customer.delete_customer_confirmation") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="deleteCustomer(selectedCustomer)"
        :showf70Icon="false"
        :primary="false"
        type="button"
        class="
          sm:ml-3 sm:w-auto
          bg-error
          hover:bg-error
          text-white
          outline-none
        "
      >
        {{ $t("subscription.delete") }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import AppModalAddCustomer from "../../../../components/item/app-modal-add-customer.vue";
import Business_status from "@/utils/const/business_status";
export default {
  components: { AppModalAddCustomer },
  data() {
    return {
      showModalActive: false,
      showModalCustomer: false,
      showDeleteConfirmation: false,
      selectedCustomer: {},
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["customerStore/apiPaginationResponse"];
    },
    apiPaginationLoading() {
      return this.$store.getters["customerStore/apiPaginationLoading"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },

    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }

      return this.business.business_status_id != Business_status.APPROVED;
    },
  },
  mounted() {
    this.fetchListCustomer();
  },
  methods: {
    paramsChanged(filters, queryParams) {
      this.fetchListCustomer(queryParams);
    },

    async deleteCustomer(customer) {
      this.showDeleteConfirmation = false;

      const result = await this.$store.dispatch(
        "customerStore/deleteCustomer",
        customer
      );
      if (!this.$lodash.isNil(result)) {
        this.refresh();
      }
    },

    openDeleteConfirmation(customer) {
      this.selectedCustomer = customer;
      this.showDeleteConfirmation = true;
    },

    createCustomer() {
      this.hasActiveBusiness
        ? this.onClickHasActiveBusiness()
        : this.onClickCustomer();
    },

    onClickHasActiveBusiness() {
      this.showModalActive = !this.showModalActive;
      this.showModalCustomer = !this.showModalCustomer;
    },

    onClickCustomer(customer) {
      this.showModalCustomer = !this.showModalCustomer;

      if (customer == null) {
        this.$store.commit("customerStore/initCustomer");
      } else {
        this.$store.dispatch("customerStore/getCustomerById", customer._id);
      }
    },

    fetchListCustomer(queryParams) {
      this.$store.dispatch("customerStore/retrieveCustomer", queryParams);
    },
    refresh() {
      this.fetchListCustomer();
    },
  },
};
</script>
